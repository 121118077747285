// 
// buttons.scss
//

.btn {
    color: $white;

    .mdi {
        &:before {
            margin-top: -1px;
        }
    }
}

.btn-rounded {
    border-radius: 2em;
}

//
// light button
// 
.btn-light {
    color: $gray-900;
}

// 
// Dark Button
// 
.btn-dark {
    background-color: lighten($gray-800,5%);
    border-color: lighten($gray-800,5%);
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    background-color: transparent;
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
        background-color: transparent;
        border-color: transparent;
    }
    &:focus,
    &.focus {
        text-decoration: $link-hover-decoration;
        border-color: transparent;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    } // No need for an active state here
} 
//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

// 
// Button labels
//

.btn-label {
    margin: -.55rem .9rem -.55rem -.9rem;
    padding: .6rem .9rem;
    background-color: rgba($gray-900,0.1);
}

.btn-label-right {
    margin: -.55rem -.9rem -.55rem .9rem;
    padding: .6rem .9rem;
    background-color: rgba($gray-900,0.1);
}

// 
// Button Extra Small Size
// 

.btn-xs {
    padding: .2rem .6rem;
    font-size: .75rem;
    border-radius: .15rem;
}